import { getLanguage } from '@/lib/config';
import { ComponentProps } from '@components/ComponentProps';
import {
  BorderRadius,
  buttonPrimary,
  buttonSecondary,
  GridWrapper,
  h4,
  hFeature,
  mediaQueries,
  paragraph,
} from '@theme/lib';
import { ReactElement } from 'react';
import styled, { css } from 'styled-components';
import { WYSIWYGFragment } from './__generated__/WYSIWYGFragment';

type WYSIWYGWrapperProps = {
  admin: boolean;
  textAlign?: string;
};

const WYSIWYGWrapper = styled(GridWrapper)<WYSIWYGWrapperProps>`
  color: ${(props) => props.theme.colors.text};

  // Specific tag styling
  .btn-primary {
    ${buttonPrimary}
  }
  /* ensure primary class takes precedence */
  /* over secondary (in case both are applied) */
  *:not(.btn-primary).btn-secondary {
    ${buttonSecondary}
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin-top: 1rem;
  }

  ul,
  ol {
    margin: 1rem 0 0 0.5rem;
    ${paragraph}
  }

  ul {
    padding-left: 1.1rem;
  }

  ol {
    padding-left: 1rem;
  }

  li {
    ${paragraph}
    list-style-position: outside;
  }

  .table-wrapper {
    overflow-x: auto;
    margin: 2rem 0;
    ${mediaQueries.lg} {
      margin: 3rem 0;
    }
  }
  table {
    border-collapse: separate;
    border-spacing: 0;
  }
  table,
  th,
  td {
    word-break: normal;
    text-align: left;
    vertical-align: middle;
  }
  th {
    ${h4}
    color: ${props => props.theme.colors.tableHeading};
    padding: 23px 14px;
  }
  td {
    ${paragraph}
    color: ${props => props.theme.colors.text};
    padding: 12px 14px;
  }
  table caption {
    ${paragraph}
    margin-bottom: 0.5rem;
  }
  table tr > * {
    border-top: 1px solid ${props => props.theme.colors.primary};
    border-left: 1px solid ${props => props.theme.colors.primary};
  }
  table tr > :first-child {
    padding-left: 1.5rem;
  }
  table tr > :last-child {
    padding-right: 1.5rem;
    border-right: 1px solid ${props => props.theme.colors.primary};
  }
  table tr:last-child > * {
    border-bottom: 1px solid ${props => props.theme.colors.primary};
  }
  table tr:first-child > :first-child {
    border-top-left-radius: ${props => props.theme.config.tableRadius.mobile};
    ${mediaQueries.lg} {
      border-top-left-radius: ${props => props.theme.config.tableRadius.desktop};
    }
  }
  table tr:first-child > :last-child {
    border-top-right-radius: ${props => props.theme.config.tableRadius.mobile};
    ${mediaQueries.lg} {
      border-top-right-radius: ${props => props.theme.config.tableRadius.desktop};
    }
  }
  table tr:last-child > :first-child {
    border-bottom-left-radius: ${props => props.theme.config.tableRadius.mobile};
    ${mediaQueries.lg} {
      border-bottom-left-radius: ${props => props.theme.config.tableRadius.desktop};
    }
  }
  table tr:last-child > :last-child {
    border-bottom-right-radius: ${props => props.theme.config.tableRadius.mobile};
    ${mediaQueries.lg} {
      border-bottom-right-radius: ${props => props.theme.config.tableRadius.desktop};
    }
  }

  iframe,
  article.media {
    margin-top: 1rem;
    width: 100%;
  }
  iframe[src=""],
  iframe:not([src]) {
    background-color: rgba(0,0,0,0.3);
  }

  img {
    ${(props) => props.theme.key !== 'zsl' && BorderRadius(props.theme.config.imageBorderRadius.mobile)};
    ${mediaQueries.lg} {
      ${(props) => props.theme.key !== 'zsl' && BorderRadius(props.theme.config.imageBorderRadius.desktop)};
    }
    width: 100%;
    height: auto;
  }
  a img {
    border-radius: 0;
    --smooth-corners: 0;
    mask-image: none;
  }

  ${mediaQueries.lg} {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    ul,
    ol,
    article.media {
      margin-top: 2rem;
    }
  }

  // Fix video aspect ratio
  .field--name-field-media-oembed-video {
    width: 100%;
    aspect-ratio: 16 / 9;
  }

  .media-oembed-content {
    border-radius: ${props => props.theme.config.videoBorderRadius.mobile};
    ${mediaQueries.lg} {
      border-radius: ${props => props.theme.config.videoBorderRadius.desktop};
    }
    width: 100%;
    height: 100%;
  }

  audio,
  video,
  iframe {
    width: 100%;
  }

  .tripadvisor {
    a[target='_blank']::after {
      display: none;
    }
  }

  & > *:first-child,
  & > *:first-child > p:first-child {
    margin-top: 0;
  }

  & > .tripadvisor > * {
    margin: ${(props) => (props.alignment === 'center' || props.alignment === 'centre') && "0 auto;" };
  }


  ${(props) =>
    props.admin &&
    css`
      text-align: center;

      li {
        list-style-position: inside;
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        ${props.theme.key === 'zsl' ? hFeature : ``};
        color: ${props.theme.key === 'zsl' ? props.theme.colors.tertiary : undefined};
      }
    `}
    ${(props) =>
      props.textAlign &&
      css`
        text-align: ${props.textAlign};
      `}

  .text-align-center {
    text-align: center;
  }
  .text-align-right {
    text-align: right;
  }
  .text-align-justify {
    text-align: justify;
  }
`;

export type WYSIWYGProps = ComponentProps<WYSIWYGFragment> & {
  admin?: boolean;
  textAlign?: string;
};

export default function WYSIWYG({
  alignment,
  textAlign,
  text: { processed, format },
}: WYSIWYGProps): ReactElement {
  const language = getLanguage();
  return (
    <WYSIWYGWrapper
      className={'wysiwyg'}
      alignment={alignment}
      textAlign={textAlign}
      dangerouslySetInnerHTML={{ __html: processed }}
      admin={format && format === 'impact_rich_text' ? true : false}
      lang={language !== 'en' ? language : undefined}
    />
  );
}
