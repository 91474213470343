import Link from 'next/link';
import { ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import { StyledBlock } from './Donate.styles';
import { DonateFixedAmountParagraph } from './__generated__/DonateFixedAmountParagraph';
import { DonateMultipleAmountsParagraph } from './__generated__/DonateMultipleAmountsParagraph';
import PadlockSVG from './assets/padlock.svg';
import { Float } from 'schema-dts';

export type DonateParagraphContentType = DonateFixedAmountParagraph | DonateMultipleAmountsParagraph;

export type DonateParagraphProps = {
  content: DonateParagraphContentType
};

export default function Donate({ content, referrer }: { content: DonateParagraphContentType | null, referrer: string }) {
  if (content) {
    return (<DonateComponent content={content} referrer={referrer} />)
  }
  return <></>
}

function DonateComponent({content, referrer }: { content: DonateParagraphContentType, referrer: string }): ReactElement {
  const { title, description, callToAction } = content;
  const isMultiple = content.__typename === 'DonateMultipleAmountsParagraph';
  const isFixed = content.__typename === 'DonateFixedAmountParagraph';
  const hasMoreFrequencies = isMultiple && (content.monthlyOptions || content.singleOptions);

  const [amount, setAmount] = useState<Float>(0);
  const [frequency, setFrequency] = useState<string>('');

  // Other input.
  const [showOther, setShowOther] = useState<boolean>(false);
  const [otherInput, setOtherInput] = useState<string>('');
  const [otherInputValid, setOtherInputValid] = useState<boolean>(true);
  const otherInputRef = useRef<HTMLInputElement | null>(null);

  const handleFrequencyClick = (value: string) => {
    if (hasMoreFrequencies) {
      // Set frequency to this value.
      setFrequency(value);

      // Set amount to default in this frequency.
      if (value === 'monthly') {
        const monthlyDefault = content.monthlyOptions?.options.find(item => item.isDefault);
        if (monthlyDefault && monthlyDefault.value) {
          setAmount(monthlyDefault.value);
        }
      }
      else if (value === 'single') {
        const singleDefault = content.singleOptions?.options.find(item => item.isDefault);
        if (singleDefault && singleDefault.value) {
          setAmount(singleDefault.value);
        }
      }
      // Hide other.
      setShowOther(false);
      // Remove other validation.
      setOtherInputValid(true);
    }
  }

  const handleAmountClick = (value: Float) => {
    // Set amount this amount.
    setAmount(value);
    // Hide other.
    setShowOther(false);
    // Remove other validation.
    setOtherInputValid(true);
  }

  const handleOtherClick = () => {
    // Set amount this amount.
    setShowOther(true);
    // Remove default from others.
    setAmount(0);
  }

  const handleOtherInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    // Set input value.
    setOtherInput(value);

    // Check the pattern for a positive number.
    if (/^[1-9]\d{0,4}((.[0-9]{1,2})?)$/.test(value)) {
      // Hide validation.
      setOtherInputValid(true);

      // Set amount this amount.
      setAmount(parseFloat(value));

    } else {
      setOtherInputValid(false);
    }
  }

  const handleCtaClick = () => {
    // Check a condition before focusing on the input field
    if (otherInputRef && !otherInputValid && otherInputRef.current) {
      // Focus on the input field
      otherInputRef.current.focus();
    }
  };

  useEffect(() => {
    // Set type dependent variables.
    if (isFixed) {
      setAmount(content.amount);
      setFrequency(content.frequency);
    } else if (isMultiple) {
      if (content.singleOptions?.isDefault) {
        setFrequency('single');
        const singleDefault = content.singleOptions.options.find(item => item.isDefault);
        if (singleDefault && singleDefault.value) {
          setAmount(singleDefault.value);
        }
      } else if (content.monthlyOptions) {
        setFrequency('monthly');
        const monthlyDefault = content.monthlyOptions.options.find(item => item.isDefault);
        if (monthlyDefault && monthlyDefault.value) {
          setAmount(monthlyDefault.value);
        }
      }
    }
  }, [content, isFixed, isMultiple])

  const currentDescription = useMemo(() => {
    if (hasMoreFrequencies && !showOther) {
      if (frequency === 'monthly') {
        const activeOption = content.monthlyOptions?.options.find(i => i.value == amount);
        if (activeOption) {
          return activeOption.description ?? description;
        }

        const monthlyDefault = content.monthlyOptions?.options.find(item => item.isDefault);
        return monthlyDefault?.description ?? description;
      }
      else if (frequency === 'single') {
        const activeOption = content.singleOptions?.options.find(i => i.value == amount);
        if (activeOption) {
          return activeOption.description ?? description;
        }

        const singleDefault = content.singleOptions?.options.find(item => item.isDefault);
        return singleDefault?.description ?? description;
      }
    }
    return description;
  }, [amount, hasMoreFrequencies, showOther, description, frequency, content]);

  // Prepare donate URL.
  let donateUrl = new URL(callToAction.url);
  donateUrl.searchParams.append('frequency', frequency);
  donateUrl.searchParams.append('custom-amount', `${amount}`);
  donateUrl.searchParams.append('referrer', `${referrer}`);

  return <StyledBlock className='donate-component'>
      <div className="title">{title}</div>
      { isMultiple ?
      <>
        <div className={`frequency-select-wrapper ${ hasMoreFrequencies ?'two-options' : ''}`}>
          <div className="buttons-wrapper">
            {content.monthlyOptions ?
              <button onClick={() => handleFrequencyClick('monthly')} className={`${frequency === 'monthly' ? ' active' : ''}`}>
                <span>GIVE MONTHLY</span>
              </button>
            : null}
            {content.singleOptions ?
              <button onClick={() => handleFrequencyClick('single')} className={`${frequency === 'single' ? ' active' : ''}`}>
                <span>SINGLE GIFT</span>
              </button>
              : null
            }
          </div>
        </div>
        {/* Frequency selector */}
        <div className="value-select-wrapper">
        {frequency === 'monthly' && content.monthlyOptions ?
          <div className="buttons-wrapper">
            {content.monthlyOptions.options.map(option =>
              <button onClick={() => handleAmountClick(option.value)} key={`monthly-${option.value}`} className={`monthly-options${option.value === amount ? ' active' : ''}`}>
                <span>{option.label}</span>
              </button>
            )}
            {content.monthlyOptions.showFieldOther ?
              <button className={`monthly-options${showOther ? ' active' : ''}`} onClick={handleOtherClick}>
                <span>Other</span>
              </button>
              : null
            }
          </div>
          : null
        }
        {/* Options list */}
        {frequency === 'single' && content.singleOptions ?
          <div className="buttons-wrapper">
            {content.singleOptions.options.map(option =>
              <button onClick={() => handleAmountClick(option.value)} key={`single-${option.value}`} className={`single-options${option.value === amount ? ' active' : ''}`}>
                <span>{option.label}</span>
              </button>
            )}
            {content.singleOptions.showFieldOther ?
              <button className={`monthly-options${showOther ? ' active' : ''}`} onClick={handleOtherClick}>
                <span>Other</span>
              </button>
              : null
            }
          </div>
          : null
        }
        </div>
      </>
      : null }


        {/* Other field */}
        { showOther ?
          <>
            <label htmlFor="other_amount">
              <span className="visually-hidden">Enter donation amount (required)</span>
              <input
                type="number"
                id="other_amount"
                className={`other-amount${!otherInputValid ? ' errors' : ''}`}
                name="other_amount"
                step={.01}
                min={1}
                max={99999}
                placeholder="Enter an amount"
                value={otherInput}
                ref={otherInputRef}
                onChange={handleOtherInput}
                />
            </label>

            { !otherInputValid ?
              <div className="other-validation-message-wrapper" role="alert">
                <div className="other-validation-message">
                  Please enter a number between 1 and 99999
                </div>
              </div>
              : null
            }
          </>
          : null
        }

      {/* Description and CTA */}
      <div className="description">{ currentDescription ?? description }</div>
      <div className="cta-wrapper">
        <Link href={otherInputValid ? donateUrl.toString() : "#"}>
          <a
            className={`${!otherInputValid ? 'errors' : ''}`}
            onClick={handleCtaClick}
            target={callToAction.target || '_self'}
            >
            {callToAction.title}<span className="visually-hidden">. Give a {frequency} £{amount} donation</span>
            <PadlockSVG />
          </a>
        </Link>
      </div>
    </StyledBlock>;
}
